import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  filtere_documents: [],
  documentDetails: {},
  search_result: [],
  list_documents: [],
  list_persons: [],
  list_document_categories: [],
  list_document_types: [],
  favorite_filters: [],
  upload_status: 'waiting',
  task_id: null,
  loading: false,
  isloadingDetails: false,
  isFiltering: false,
  error: false,
  minimumLoadTimeElapsed: false, 
};

const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    //for Filter criteria
    getFilterCriteria: (state) => {
      state.loading = true;
      state.error = false;
    },

    filteredDocuments: (state, { payload }) => {
      state.filtere_documents = payload;
      state.loading = false;
      state.error = false;
    },

    filterFailed: (state) => {
      state.error = true;
      state.loading = false;
    },

    onFiltering: (state, { payload }) => {
      state.isFiltering = payload;
    },

    //For document details
    getDocumentDetail: (state) => {
      state.isloadingDetails = true;
    },

    documentDetailsState: (state, { payload }) => {
      state.documentDetails = payload;
      state.isloadingDetails = false;
      state.error = false;
    },

    getDocumentDetailFailed: (state) => {
      state.isloadingDetails = false;
      state.error = true;
    },

    //For full text search
    getSearchResult: (state) => {
      state.loading = true;
    },

    searchResultState: (state, { payload }) => {
      state.search_result = payload;
      state.loading = false;
      state.error = false;
    },

    getSearchResultFailed: (state) => {
      state.error = true;
      state.loading = false;
    },

    //For listing all documents
    getDocumentList: (state) => {
      state.loading = true;
      state.error = null;
      state.minimumLoadTimeElapsed = false;
    },

    minimumLoadTimeReached: (state) => {
      state.minimumLoadTimeElapsed = true;
    },

    listDocumentsState: (state, { payload }) => {
      state.list_documents = payload;
      state.loading = false;
      state.error = false;
    },

    getDocumentListFailed: (state) => {
      state.error = true;
      state.loading = false;
    },

    //handle get document category
    getDocumentCategory: (state, { payload }) => {
      state.list_document_categories = payload;
    },

    //handle get document types
    getDocumentTypes: (state, { payload }) => {
      state.list_document_types = payload;
    },

    //hanlde list all persons
    getPersons: (state, { payload }) => {
      state.list_persons = payload;
    },

    //For listing all favroite filters
    getFavoriteFilters: (state) => {
      state.loading = true;
    },

    listFavoriteFiltersState: (state, { payload }) => {
      state.favorite_filters = payload;
      state.loading = false;
      state.error = false;
    },

    getFavoriteFiltersFailed: (state) => {
      state.error = true;
      state.loading = false;
    },

    //For document upload status
    uploadStatus: (state, { payload }) => {
      state.upload_status = payload;
    },
    taskId: (state, { payload }) => {
      state.task_id = payload;
    },
  },
});

export const {
  getFilterCriteria,
  filteredDocuments,
  filterFailed,
  onFiltering,
  getDocumentDetail,
  documentDetailsState,
  getDocumentDetailFailed,
  getSearchResult,
  searchResultState,
  getSearchResultFailed,
  getDocumentList,
  listDocumentsState,
  getDocumentListFailed,
  getDocumentCategory,
  getDocumentTypes,
  getPersons,
  getFavoriteFilters,
  getFavoriteFiltersFailed,
  listFavoriteFiltersState,
  getFa,
  uploadStatus,
  taskId,
  minimumLoadTimeReached,
} = documentSlice.actions;

export default documentSlice.reducer;
