import React, { memo, useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Document, Page, pdfjs } from 'react-pdf';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import compose from 'lodash/fp/compose';
import ListDocumentLottie from 'lottie-react';
import { documentSlice } from 'rootStateHandler/selectors';
import animatedLoading from 'assets/lottie/admit-cat-loader.json';
import { withTranslation } from '../../../hoc';
import { editIcon, deleteIcon, viewIcon } from '../../../../libs/icon';
import EmptyState from 'components/common/EmptyState';
import './listDocuments.css';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// Utility functions
const getFileExtension = (filename) => filename.split('.').pop() || '';
const transformToCloudFrontUrl = (s3Url) => s3Url ? `https://download.admincat.net/media/${new URL(s3Url).pathname.split('/media/')[1]}` : '';
const isImageFile = (filename) => ['gif', 'png', 'jpg', 'jpeg'].includes(getFileExtension(filename).toLowerCase());
const truncateString = (str, len) => !str ? '' : str.length >= len ? `${str.substring(0, len)}...${getFileExtension(str)}` : str;

const LoadingState = ({ tr }) => {
  const [showContent, setShowContent] = useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => setShowContent(true), 5000);
    return () => clearTimeout(timer);
  }, []);

  return showContent ? (
    <div className="lottie_loader_document">
      <ListDocumentLottie loop autoplay animationData={animatedLoading} />
      <div className="loading_text">{tr('Loading...')}</div>
    </div>
  ) : null;
};

const EmptyStateWrapper = ({ text }) => (
  <div className="empty_state_container">
    <EmptyState text={text} hasButton={false} />
  </div>
);

const LazyImage = memo(({ src, alt, className, width, height }) => {
  const cloudFrontSrc = useMemo(() => transformToCloudFrontUrl(src), [src]);
  const optimizedSrc = `${cloudFrontSrc}${cloudFrontSrc.includes('?') ? '&' : '?'}width=${width}&height=${height}&format=webp&quality=80`;

  return (
    <div style={{ width, height }}>
      <LazyLoadImage
        alt={alt}
        height={height}
        src={optimizedSrc}
        width={width}
        className={className}
        effect="blur"
      />
    </div>
  );
});

const PDFPreview = memo(({ file }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const cloudFrontFile = useMemo(() => transformToCloudFrontUrl(file), [file]);

  return (
    <div className="pdf-wrapper">
      <Document
        file={{ url: cloudFrontFile }}
        loading={null}
        onLoadSuccess={() => setIsLoaded(true)}
        onLoadError={(error) => {
          console.error('PDF loading error:', error);
          setError(error);
        }}
        options={{
          cMapUrl: `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/cmaps/`,
          cMapPacked: true,
          disableAutoFetch: true,
          disableStream: true,
        }}
      >
        {isLoaded && (
          <Page
            pageNumber={1}
            width={62}
            className="pdf-page"
            renderTextLayer={false}
            renderAnnotationLayer={false}
            loading=""
          />
        )}
      </Document>
      {error && <div className="pdf-error">Failed to load</div>}
    </div>
  );
});

const DocumentPreviewItem = memo(({ document, isSelected, onSelect, onEdit, onDelete }) => {
  const optimizedFileUrl = useMemo(() => transformToCloudFrontUrl(document.file), [document.file]);
  const optimizedSnippetUrl = useMemo(() => transformToCloudFrontUrl(document.snippet), [document.snippet]);
  const downloadUrl = useMemo(() => transformToCloudFrontUrl(
    `https://admincat-project-files.s3.amazonaws.com/media/documents/${document.filename.split(' ').join('_')}`
  ), [document.filename]);

  return (
    <div className="document_preview_container">
      <div className="document_preview" aria-selected={isSelected} onClick={() => onSelect(document.id)}>
        {isImageFile(document.filename) ? (
          <LazyImage src={optimizedSnippetUrl} alt="document" className="preview-content" width={62} height={85} />
        ) : (
          <PDFPreview file={optimizedFileUrl} />
        )}

        {document.id && (
          <div className={isSelected ? 'popIcon' : 'hideIcon'}>
            <span onClick={(e) => { e.stopPropagation(); onEdit(); }}>{editIcon}</span>
            <span>
              <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
                {viewIcon}
              </a>
            </span>
            <span onClick={(e) => { e.stopPropagation(); onDelete(); }}>{deleteIcon}</span>
          </div>
        )}
      </div>
      <p className="documentTitle" style={{ color: 'var(--admincat-color-grey-2)' }}>
        {truncateString(document.filename, 10)}
      </p>
    </div>
  );
});

const DocumentPreviews = memo(({ documents, selectedId, onSelect, onEdit, onDelete }) => (
  <div className="list_documents_container">
    {documents.map((document) => (
      <DocumentPreviewItem
        key={document.id}
        document={document}
        isSelected={selectedId === document.id}
        onSelect={onSelect}
        onEdit={onEdit}
        onDelete={onDelete}
      />
    ))}
  </div>
));

const FilteredDocuments = memo(({ documents, selectedId, onSelect, onEdit, onDelete, onView, tr }) => (
  <div className="list_documents_container">
    {documents.map((document) => (
      <div key={document.id} className="document_preview_container">
        <div className="document_preview" aria-selected={selectedId === document.id} onClick={() => onSelect(document.id)}>
          {document.snippet ? (
            <LazyImage src={document.snippet} alt="snippet" width={85} height={118} />
          ) : (
            <PDFPreview file={document.file} />
          )}
          <div className={selectedId === document.id ? 'popIcon' : 'hideIcon'}>
            <span onClick={onEdit}>{editIcon}</span>
            <span onClick={onView}>{viewIcon}</span>
            <span onClick={onDelete}>{deleteIcon}</span>
          </div>
        </div>
      </div>
    ))}
  </div>
));

const ListDocuments = (props) => {
  const {
    handleDisplayInformation,
    isSearching,
    document_id,
    handleDelete,
    handleEdit,
    handleViewDocument,
    tr,
  } = props;

  const { 
    filtere_documents, 
    loading, 
    list_documents, 
    search_result, 
    isFiltering,
    minimumLoadTimeElapsed,
    error 
  } = useSelector(documentSlice);

  const filteredDocuments = useMemo(() => [...filtere_documents], [filtere_documents]);

  const DocumentSearchPreviews = useMemo(() => {
    if (loading || !minimumLoadTimeElapsed) return <LoadingState tr={tr} />;
    if (!search_result?.length) return <EmptyStateWrapper text={tr('No result was found...')} />;

    return (
      <div className="filtered_documents_container">
        <p>{tr('Results found')}: {search_result.length} {tr('result(s)')}</p>
        {search_result.map((document) => (
          <div key={document.id} className="filtered_documents_wrapper">
            <div className="filtered_document_preview" onClick={() => handleDisplayInformation(document.id)}>
              {document.snippet ? (
                <LazyImage src={document.snippet} alt="snippet" width={85} height={118} />
              ) : (
                <PDFPreview file={document.file} />
              )}
            </div>
            <div className="filtered_preview_info">
              <p>{truncateString(document.filename, 30)}</p>
              <p>{truncateString(document.description, 30)}</p>
            </div>
          </div>
        ))}
      </div>
    );
  }, [loading, minimumLoadTimeElapsed, search_result, tr, handleDisplayInformation]);

  const renderMainDocuments = useMemo(() => {
    if (loading || !minimumLoadTimeElapsed) return <LoadingState tr={tr} />;
    if (list_documents.length === 0) return <EmptyStateWrapper text={tr('No document uploaded yet!')} />;

    return (
      <DocumentPreviews
        documents={list_documents}
        selectedId={document_id}
        onSelect={handleDisplayInformation}
        onEdit={handleEdit}
        onDelete={handleDelete}
      />
    );
  }, [loading, minimumLoadTimeElapsed, list_documents, document_id, tr, handleDisplayInformation, handleEdit, handleDelete]);

  const renderFilteredDocuments = useMemo(() => {
    if (loading || !minimumLoadTimeElapsed) return <LoadingState tr={tr} />;
    if (!filteredDocuments?.length) return <EmptyStateWrapper text={tr('No result')} />;

    return (
      <FilteredDocuments
        documents={filteredDocuments}
        selectedId={document_id}
        onSelect={handleDisplayInformation}
        onEdit={handleEdit}
        onDelete={handleDelete}
        onView={handleViewDocument}
        tr={tr}
      />
    );
  }, [loading, minimumLoadTimeElapsed, filteredDocuments, document_id, tr, handleDisplayInformation, handleEdit, handleDelete, handleViewDocument]);

  return (
    <>
      {isFiltering && renderFilteredDocuments}
      {!isSearching && !isFiltering && renderMainDocuments}
      {isSearching && DocumentSearchPreviews}
    </>
  );
};

export default compose(withTranslation)(ListDocuments);

// // Configure PDF.js worker once
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// // Utility functions
// const getFileExtension = (filename) => {
//   const parts = filename.split('.');
//   return parts.length > 1 ? parts.pop() || '' : '';
// };

// const transformToCloudFrontUrl = (s3Url) => {
//   if (!s3Url) return '';
//   const url = new URL(s3Url);
//   const pathParts = url.pathname.split('/media/');
//   if (pathParts.length < 2) return s3Url;
//   const cloudFrontDomain = 'https://download.admincat.net';
//   return `${cloudFrontDomain}/media/${pathParts[1]}`;
// };

// const isImageFile = (filename) => {
//   const fileExt = getFileExtension(filename);
//   const validImageExts = ['gif', 'png', 'jpg', 'jpeg'];
//   return validImageExts.includes(fileExt.toLowerCase());
// };

// const truncateString = (input_string, string_length) => {
//   if (!input_string || typeof input_string !== 'string') return '';

//   if (input_string.length >= string_length) {
//     const ext = getFileExtension(input_string);
//     return `${input_string.substring(0, string_length)}...${ext}`;
//   }
//   return input_string;
// };

// // Loading component
// // const LoadingState = memo(({ tr }) => (
// //   <div className="lottie_loader_document">
// //     <ListDocumentLottie loop autoplay animationData={animatedLoading} />
// //     <div className="loading_text">{tr('Loading...')}</div>
// //   </div>
// // ));

// const LoadingState = ({ tr }) => {
//   const [showContent, setShowContent] = useState(false);

//   useEffect(() => {
//     // Small delay to prevent flash of loading state for very fast requests
//     const timer = setTimeout(() => setShowContent(true), 5000);
//     return () => clearTimeout(timer);
//   }, []);

//   return showContent ? (
//     <div className="lottie_loader_document">
//       <ListDocumentLottie loop autoplay animationData={animatedLoading} />
//       <div className="loading_text">{tr('Loading...')}</div>
//     </div>
//   ) : null;
// };

// // Empty state component
// const EmptyStateWrapper = memo(({ text }) => (
//   <div className="empty_state_container">
//     <EmptyState text={text} hasButton={false} />
//   </div>
// ));

// // Lazy loaded image component
// const LazyImage = memo(({ src, alt, className, width, height }) => {
//   const cloudFrontSrc = useMemo(() => transformToCloudFrontUrl(src), [src]);

//   const optimizedSrc = `${cloudFrontSrc}${
//     cloudFrontSrc.includes('?') ? '&' : '?'
//   }width=${width}&height=${height}&format=webp&quality=80`;

//   return (
//     <div style={{ width, height }}>
//       <LazyLoadImage
//         alt={alt}
//         height={height}
//         src={optimizedSrc}
//         width={width}
//         className={className}
//         effect="blur"
//       />
//     </div>
//   );
// });

// // PDF preview
// const PDFPreview = memo(({ file, tr }) => {
//   const [isLoaded, setIsLoaded] = useState(false);
//   const [error, setError] = useState(null);

//   const cloudFrontFile = useMemo(() => transformToCloudFrontUrl(file), [file]);

//   const handleError = (error) => {
//     console.error('PDF loading error:', error);
//     setError(error);
//   };

//   return (
//     <div className="pdf-wrapper">
//       <Document
//         file={{
//           url: cloudFrontFile,
//         }}
//         loading={null}
//         onLoadSuccess={() => setIsLoaded(true)}
//         onLoadError={handleError}
//         options={{
//           cMapUrl: `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/cmaps/`,
//           cMapPacked: true,
//           disableAutoFetch: true,
//           disableStream: true,
//         }}
//       >
//         {isLoaded && (
//           <Page
//             pageNumber={1}
//             width={62}
//             className="pdf-page"
//             renderTextLayer={false}
//             renderAnnotationLayer={false}
//             loading=""
//           />
//         )}
//       </Document>
//       {error && <div className="pdf-error">Failed to load</div>}
//     </div>
//   );
// });

// // Document preview item component
// const DocumentPreviewItem = memo(
//   ({ document, isSelected, onSelect, onEdit, onDelete }) => {
//     const handleClick = useCallback(() => {
//       onSelect(document.id);
//     }, [document.id, onSelect]);

//     const handleEditClick = useCallback(
//       (e) => {
//         e.stopPropagation();
//         onEdit();
//       },
//       [onEdit],
//     );

//     const handleDeleteClick = useCallback(
//       (e) => {
//         e.stopPropagation();
//         onDelete();
//       },
//       [onDelete],
//     );

//     const optimizedFileUrl = useMemo(
//       () => transformToCloudFrontUrl(document.file),
//       [document.file]
//     );

//     const optimizedSnippetUrl = useMemo(
//       () => transformToCloudFrontUrl(document.snippet),
//       [document.snippet]
//     );

//     const downloadUrl = useMemo(
//       () =>
//         transformToCloudFrontUrl(
//           `https://admincat-project-files.s3.amazonaws.com/media/documents/${document.filename.split(' ').join('_')}`
//         ),
//       [document.filename]
//     );

//     return (
//       <div className="document_preview_container">
//         <div
//           className="document_preview"
//           aria-selected={isSelected}
//           onClick={handleClick}
//         >
//           {isImageFile(document.filename) ? (
//             <LazyImage
//               src={optimizedSnippetUrl}
//               alt="document"
//               className="preview-content"
//               width={62}
//               height={85}
//             />
//           ) : (
//             <PDFPreview file={optimizedFileUrl} />
//           )}

//           {document.id && (
//             <div className={isSelected ? 'popIcon' : 'hideIcon'}>
//               <span onClick={handleEditClick}>{editIcon}</span>
//               <span>
//                 <a href={downloadUrl} target="_blank" rel="noopener noreferrer">
//                   {viewIcon}
//                 </a>
//               </span>
//               <span onClick={handleDeleteClick}>{deleteIcon}</span>
//             </div>
//           )}
//         </div>

//         <p
//           className="documentTitle"
//           style={{ color: 'var(--admincat-color-grey-2)' }}
//         >
//           {truncateString(document.filename, 10)}
//         </p>
//       </div>
//     );
//   }
// );

// // Filtered documents component
// const FilteredDocuments = memo(
//   ({ documents, loading, selectedId, onSelect, onEdit, onDelete, onView, tr }) => {
//     if (loading) return <LoadingState tr={tr} />;
//     if (!documents?.length) return <EmptyStateWrapper text={tr('No result')} />;

//     return (
//       <div className="list_documents_container">
//         {documents.map((document) => (
//           <div key={document?.id} className="document_preview_container">
//             <div
//               className="document_preview"
//               aria-selected={selectedId === document?.id}
//               onClick={() => onSelect(document?.id)}
//             >
//               {document?.snippet ? (
//                 <LazyImage
//                   src={document.snippet}
//                   alt="snippet"
//                   width={85}
//                   height={118}
//                 />
//               ) : (
//                 <PDFPreview file={document.file} />
//               )}

//               <div className={selectedId === document.id ? 'popIcon' : 'hideIcon'}>
//                 <span onClick={onEdit}>{editIcon}</span>
//                 <span onClick={onView}>{viewIcon}</span>
//                 <span onClick={onDelete}>{deleteIcon}</span>
//               </div>
//             </div>
//           </div>
//         ))}
//       </div>
//     );
//   }
// );

// // Document previews component
// const DocumentPreviews = memo(
//   ({ documents, loading, selectedId, onSelect, onEdit, onDelete, tr }) => {
//     const showEmptyState = !loading && (!documents || documents.length === 0);
//     const showDocuments = !loading && documents && documents.length > 0;

//     return (
//       <>
//         {loading && <LoadingState tr={tr} />}
//         {/* {showEmptyState && <EmptyStateWrapper text={tr('No document uploaded yet!')} />} */}
//         {showDocuments && (
//           <div className="list_documents_container">
//             {documents.map((document) => (
//               <DocumentPreviewItem
//                 key={document?.id}
//                 document={document}
//                 isSelected={selectedId === document?.id}
//                 onSelect={onSelect}
//                 onEdit={onEdit}
//                 onDelete={onDelete}
//               />
//             ))}
//           </div>
//         )}
//         {/* {showEmptyState && <EmptyStateWrapper text={tr('No document uploaded yet!')} />}  */}
//       </>
//     );
//   }
// );


// // Main component
// const ListDocuments = (props) => {
//   const {
//     handleDisplayInformation,
//     isSearching,
//     document_id,
//     handleDelete,
//     handleEdit,
//     handleViewDocument,
//     tr,
//   } = props;

//   const { filtere_documents, loading, list_documents, search_result, isFiltering, minimumLoadTimeElapsed, } =
//     useSelector(documentSlice);

//   const filteredDocuments = useMemo(
//     () => [...filtere_documents],
//     [filtere_documents]
//   );

//   // Memoized search results section
//   const DocumentSearchPreviews = useMemo(() => {
//     if (loading) return <LoadingState tr={tr} />;

//     if (!search_result?.length) {
//       return <EmptyStateWrapper text={tr('No result was found...')} />;
//     }

//     return (
//       <div className="filtered_documents_container">
//         <p>
//           {tr('Results found')}: {search_result.length} {tr('result(s)')}
//         </p>

//         {search_result.map((document) => (
//           <div key={document?.id} className="filtered_documents_wrapper">
//             <div
//               className="filtered_document_preview"
//               onClick={() => handleDisplayInformation(document.id)}
//             >
//               {document?.snippet ? (
//                 <LazyImage
//                   src={document.snippet}
//                   alt="snippet"
//                   width={85}
//                   height={118}
//                 />
//               ) : (
//                 <PDFPreview file={document.file} />
//               )}
//             </div>

//             <div className="filtered_preview_info">
//               <p>{truncateString(document?.filename, 30)}</p>
//               <p>{truncateString(document?.description, 30)}</p>
//             </div>
//           </div>
//         ))}
//       </div>
//     );
//   }, [loading, search_result, tr, handleDisplayInformation]);

//   return (
//     <>
//       {isFiltering && (
//         <FilteredDocuments
//           documents={filteredDocuments}
//           loading={loading}
//           selectedId={document_id}
//           onSelect={handleDisplayInformation}
//           onEdit={handleEdit}
//           onDelete={handleDelete}
//           onView={handleViewDocument}
//           tr={tr}
//         />
//       )}

//       {!isSearching && !isFiltering && (
//         <DocumentPreviews
//           documents={list_documents}
//           loading={loading}
//           selectedId={document_id}
//           onSelect={handleDisplayInformation}
//           onEdit={handleEdit}
//           onDelete={handleDelete}
//           tr={tr}
//         />
//       )}

//       {isSearching && DocumentSearchPreviews}
//     </>
//   );
// };

// export default compose(withTranslation)(ListDocuments);